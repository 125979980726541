@use 'variables';

.pie {
  width: '100%';
  height: '100%';
}
.pie_stroke {
  fill: transparent;
  stroke: variables.$primaryColor;
}
