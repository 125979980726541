@use 'variables';

.container {
  align-content: center;
  display: flex;
  font-family: monospace;
  font-size: 5em;
  font-weight: bold;
  position: relative;
  text-align: center;
  width: 100%;

  &_isReadonly {
    opacity: 0.5;
  }

  @media (min-width: 600px) {
    font-size: 8em;
  }
}

.input {
  all: unset;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  flex: 1;
  text-align: center;
  width: 2em;
  padding: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &.input_min {
    text-align: right;
  }
  &.input_sec {
    text-align: left;
  }
}

.container_isAlert {
  animation: blink-animation 1s steps(2, start) infinite;
  color: variables.$primaryColor;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
