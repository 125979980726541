.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  text-align: center;
  font-size: 3em;
  padding-top: 0.2em;

  @media (min-width: 600px) and (min-height: 600px) {
    font-size: 5em;
  }
}

.pieContainer {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 10em;
  justify-content: center;
  padding: 1em;
  position: relative;
}

.centerContainer {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bell {
  width: 70%;
  height: 70%;
}

.controlsContainer {
  text-align: center;
  padding: 0.5em 0;
}
